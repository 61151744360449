.react-date-picker__calendar--open {
    position: absolute;
}

.react-date-picker__calendar--closed {
    position: absolute;
}

.px-4 {
    margin-left: -1.5rem
}