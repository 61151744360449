.active-item {

}
.customAlign {
    align-items: end;
}
a {
    color: green;
}

a:hover {
    color: red;
}