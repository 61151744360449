@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

.rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: orangered;
}
.rdrDayToday .rdrDayNumber span:after {
    background: #E31937;
}
.rdrDateDisplayWrapper {
    display: none;
}