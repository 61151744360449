.tabs-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
}

.user-count-container {
    margin: 20px;
}

.user-count-header {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.date-selector-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 20px;
    align-items: flex-start;
}

.date-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.chart-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
}

.chart-item {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.chart-title {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
    font-weight: 600;
}

.text-box {
    margin-bottom: 15px;
    text-align: left;
}

@media screen and (max-width: 1200px) {
    .chart-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .chart-grid {
        grid-template-columns: 1fr;
    }
}
